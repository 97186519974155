import Container from '../../../layouts/Container';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import Era from '../../../static/partners/era.svg';
import Manta from '../../../static/partners/Manta.png';
import Okx from '../../../static/partners/okx.svg';
// import Aave from '../../../static/partners/aave.svg';
import RedStone from '../../../static/partners/redstone.png';
import StakeStone from '../../../static/partners/stakestone.png';
import Pyth from '../../../static/partners/pyth.svg';
import LayerZero from '../../../static/partners/LayerZero.svg';
import Gelato from '../../../static/partners/gelato.svg';
import Mute from '../../../static/partners/Mute.png';
import spacefi from '../../../static/partners/Spacefi.png';
import velocore from '../../../static/partners/Velocore.svg';
import vesync from '../../../static/partners/Vesync.svg';
import eZkalibur from '../../../static/partners/Ezkalibur.png';
// import mandus from '../../../static/partners/Mundussecurity.svg';
import holstation from '../../../static/partners/holdstation.png';
import liquity from '../../../static/partners/Liquity.svg';
import star from '../../../static/partners/Star-protocol.png';
import bitget from '../../../static/partners/Bitget.svg';
// import spearbit from '../../../static/partners/spearbit.png';
import mountain from '../../../static/partners/mountain.png';

import pufferFi from '../../../static/partners/pufferFi.svg';
import eigenPi from '../../../static/partners/eigenpie.png';
import etherfi from '../../../static/partners/etherfi.png';
import kelpDao from '../../../static/partners/kelpDao.svg';
import rezno from '../../../static/partners/renzo.svg';
import swell from '../../../static/partners/swell.svg';
import lido from '../../../static/partners/lido.png';

import bisonFund from '../../../static/investors/bisonFund.png';
// import kelsierVentures from '../../../static/investors/kelsier-ventures.png';
import blockchainFounders from '../../../static/investors/blockchainFounders.png';
import cryptoBanter from '../../../static/investors/cryptoBanter.png';
import cypherCapital from '../../../static/investors/cypherCapital.png';
import morningStar from '../../../static/investors/morningStar.png';
import transformCapital from '../../../static/investors/transformCapital.png';
import dvdt from '../../../static/investors/dvdt.png';
import momentum from '../../../static/investors/momentum.png';
import newman from '../../../static/investors/newman.png';
import consensys from '../../../static/investors/consensys-light.png';
import p2ventures from '../../../static/investors/p2ventures.png';
import blackedge from '../../../static/investors/blackedge-horizontal-white.png';

import { useMemo, useState } from 'react';
import DesktopPartners from './DesktopPartners';
import MobilePartners from './MobilePartners';

export interface IIndividualPartner {
  name: string;
  logo: string;
  link?: string;
}

const partners: { [keys: string]: IIndividualPartner[] } = {
  Infra: [
    {
      name: 'ZkSync Era',
      logo: Era,
      link: 'https://zksync.io/',
    },
    {
      name: 'Manta Network',
      logo: Manta,
      link: 'https://manta.network/',
    },
    {
      name: 'LayerZero',
      logo: LayerZero,
      link: 'https://layerzero.network',
    },
    {
      name: 'Pyth',
      logo: Pyth,
      link: 'https://www.pyth.network/',
    },
    {
      name: 'Gelato',
      logo: Gelato,
      link: 'https://www.gelato.network/',
    },
    {
      name: 'Redstone',
      logo: RedStone,
      link: 'https://redstone.finance/',
    },
  ],
  Defi: [
    /*{
      name: 'Aave',
      logo: Aave,
      link: 'https://app.zerolend.xyz',
    },*/
    {
      name: 'Liquity',
      logo: liquity,
      link: 'https://www.liquity.org/',
    },
    {
      name: 'HoldStation',
      logo: holstation,
      link: 'https://holdstation.com/',
    },
    {
      name: 'Star Protocol',
      logo: star,
    },
  ],
  'LSDs/LRTSs': [

    {
      name: 'Stakestone',
      logo: StakeStone,
      link: 'https://stakestone.io/#/home',
    },
    {
      name: 'Lido',
      logo: lido,
      link: 'https://lido.fi/',
    },
    {
      name: 'Etherfi',
      logo: etherfi,
      link: 'https://app.ether.fi/',
    },
    {
      name: 'renzo',
      logo: rezno,
      link: 'https://www.renzoprotocol.com',
    },
    {
      name: 'KelpDap',
      logo: kelpDao,
      link: 'https://kelpdao.xyz/',
    },
    {
      name: 'EigenPie',
      logo: eigenPi,
      link: 'https://www.eigenlayer.magpiexyz.io/restake',
    },
    {
      name: 'PufferFi',
      logo: pufferFi,
      link: 'https://www.puffer.fi/',
    },
    {
      name: 'Swell',
      logo: swell,
      link: 'https://app.swellnetwork.io/',
    },
    {
      name: 'Mountain',
      logo: mountain,
      link: 'https://mountainprotocol.com/',
    },
  ],
  Dexes: [
    {
      name: 'Mute',
      logo: Mute,
      link: 'https://app.mute.io/swap',
    },
    {
      name: 'SpaceFi',
      logo: spacefi,
      link: 'https://www.spacefi.io/Home',
    },
    {
      name: 'Velocore',
      logo: velocore,
      link: 'https://velocore.xyz/',
    },
    {
      name: 'vesync',
      logo: vesync,
      link: 'https://vesync.finance/',
    },
   /* {
      name: 'eZkalibur',
      logo: eZkalibur,
      link: 'https://app.zerolend.xyz',
    },*/
  ],
  // All: [],
  Miscellaneous: [
    {
      name: 'OKX',
      logo: Okx,
      link: 'https://www.okx.com/',
    },
    {
      name: 'Bitget',
      logo: bitget,
      link: 'https://www.bitget.com/',
    },
  ],
  /*Audits: [
    /!*{
      name: 'Halborn',
      logo: halborn,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Peckshield',
      logo: peckshield,
      link: 'https://app.zerolend.xyz',
    },*!/
    {
      name: 'Mundus Dev ',
      logo: mandus,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Spearbit by Cantina ',
      logo: spearbit,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Chaos Labs',
      logo: chaos,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Immunefi',
      logo: immunefi,
      link: 'https://app.zerolend.xyz',
    },
  ],*/
  Investors: [
    {
      name: 'Consensys',
      logo: consensys,
      link: 'https://consensys.io/',
    },
    {
      name: 'Polygon Ventures',
      logo: p2ventures,
      link: 'https://polygon.technology/blog/polygon-ventures-backing-the-bold',
    },
    {
      name: 'Blockchain Founders',
      logo: blockchainFounders,
      link: 'https://blockchainff.com/',
    },
    {
      name: 'Momentum',
      logo: momentum,
    },
    {
      name: 'Morning Star',
      logo: morningStar,
      link: 'https://morningstar.ventures/',
    },
    {
      name: 'Crypto Banter',
      logo: cryptoBanter,
      link: 'https://www.cryptobanter.com/',
    },
    {
      name: 'Newman Capital',
      logo: newman,
      link: 'https://newmancapital.com/',
    },
    {
      name: 'dV/dT',
      logo: dvdt,
      link: 'http://www.dvdt.capital/',
    },
    {
      name: 'Transform Capital',
      logo: transformCapital,
      link: 'https://www.transform-cap.com/',
    },
    {
      name: 'Cypher Capital',
      logo: cypherCapital,
      link: 'https://cyphercapital.com/',
    },
    {
      name: 'Bison Fund',
      logo: bisonFund,
      link: 'https://www.bison.fund/',
    },
    {
      name: 'Black Edge',
      logo: blackedge,
      link: 'https://www.blackedge.io/',
    },
    /* {
       name: 'BDE Ventures',
       logo: bisonFund,
       link: '',
     },*/

    /* {
       name: 'Box Mining',
       logo: boxMining,
       link: '',
     },
     {
       name: 'Ethernity',
       logo: ethernity,
       link: '',
     },
     {
       name: 'Founder Heads',
       logo: founderHeads,
       link: '',
     },
     {
       name: 'Golden Tree',
       logo: GoldenTree,
       link: '',
     },
     {
       name: 'iAngels',
       logo: iangels,
       link: '',
     },
 
     {
       name: 'Krypital',
       logo: krypital,
       link: '',
     },
 
     {
       name: 'Ozaru',
       logo: ozaru,
       link: '',
     },
 
     {
       name: 'Via BTC Capital',
       logo: viaBTCCapital,
       link: '',
     },
     {
       name: 'WoodStock',
       logo: woodStock,
       link: '',
     },*/
  ],
};

/*
let all: IIndividualPartner[] = [];
Object.values(partners).forEach((data) => {
  all = all.concat(data);
});

partners['All'] = all;
*/

const Partners = () => {
  const [selectedType, setSelectedType] = useState<string>(
    Object.keys(partners)[0],
  );

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  const typesData: IIndividualPartner[] = useMemo(() => {
    // @ts-ignore
    return partners[selectedType];
  }, [selectedType]);

  return (
    <Box pb={40}>
      <Container>
        <Text fontSize={28} fontWeight={600} textAlign={'center'} mb={2}>
          Partners and Collaborations
        </Text>
        <Text fontSize={20} opacity={0.6} textAlign={'center'} mb={6}>
          A growing ecosystem of DApps to simplify the DeFi lending experience
        </Text>
        <Box
          p={3}
          borderRadius={'42px'}
          display={'flex'}
          justifyContent={'center'}
          w={{ base: '100%', md: 'max-content' }}
          mx={'auto'}
          className={'theme-box-shadow'}
          bg={'black'}
          position={'relative'}
          zIndex={10}
          maxW={'100vw'}
          overflowX={'scroll'}
          flexWrap={{ base: 'wrap', md: 'unset' }}
        >
          {Object.keys(partners).map((key, index) => {
            return (
              <Box>
                <Box
                  bg={key === selectedType ? 'white' : 'transparent'}
                  py={2}
                  px={6}
                  mx={2}
                  my={{ base: 2, md: 0 }}
                  w={'max-content'}
                  color={key === selectedType ? 'black' : 'white'}
                  borderRadius={'20px'}
                  cursor={'pointer'}
                  key={index}
                  opacity={key === selectedType ? 1 : 0.6}
                  onClick={() => setSelectedType(key)}
                >
                  {key}
                </Box>
              </Box>
            );
          })}
        </Box>

        {isLargerThan1280 ? (
          <DesktopPartners data={typesData} />
        ) : (
          <MobilePartners data={typesData} />
        )}
      </Container>
    </Box>
  );
};

export default Partners;
