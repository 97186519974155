import { IIndividualPartner } from './Partners';
import {Box, Image, Link} from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';

interface IProps {
  data: IIndividualPartner[];
}

const DesktopPartners = (props: IProps) => {
  const { data } = props;

  const noOfColumn = 6;
  const noOfRows = Math.ceil(data.length / noOfColumn);
  const arr = Array.from(Array(noOfRows).keys());

  return (
    <Box
      w={'100%'}
      minH={{ base: '100px', md: '258px' }}
      mt={8}
      // borderRadius={{ base: '50px', md: '169px' }}
      // className={'theme-box-shadow'}
      // mt={{ base: 0, md: '-32px' }}
      // display={'flex'}
      // alignItems={'center'}
      // justifyContent={'center'}
      overflow={'hidden'}
      position={'relative'}
      /* _before={{
         content: '""',
         position: 'absolute',
         bg: 'linear-gradient(to right, black, transparent)',
         left: -24,
         width: { base: 0, md: '40%' },
         height: { base: 0, md: '100%' },
         borderRadius: '60px',
         zIndex: 8,
       }}
       _after={{
         content: '""',
         position: 'absolute',
         bg: 'linear-gradient(to left, black, transparent)',
         right: -24,
         width: { base: 0, md: '40%' },
         height: { base: 0, md: '100%' },
         borderRadius: '60px',
         zIndex: 8,
       }}*/
    >
      {arr.map((_d, index) => {
        const dataArr = data.slice(
          index * noOfColumn,
          (index + 1) * noOfColumn,
        );
        const marquee = false && data.length >= noOfColumn;
        const dataToMap = marquee ? dataArr.concat(dataArr) : dataArr;
        const direction = index % 2 !== 0 ? 'right' : 'left';

        if (!marquee)
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              maxW={'100vw'}
              mb={6}
              minH={{ base: '120px', md: '120px' }}
            >
              {dataToMap.map((_d, index) => {
                return (
                  <Box
                    as={Link}
                    href={_d.link? _d.link: ''}
                    px={{ base: 0, md: 6 }}
                    mx={{ base: 0, md: 2 }}
                    target={'_blank'}
                  >
                    <Image
                      src={_d.logo}
                      alt={_d.name}
                      cursor={'pointer'}
                      filter={'grayScale(1)'}
                      transition={'0.5s ease-in-out'}
                      _hover={{
                        transform: 'scale(1.1)',
                        filter: 'none'
                      }}
                      w={{ base: '80px', md: '120px' }}
                    />
                  </Box>
                );
              })}
            </Box>
          );
        else
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              maxW={'100vw'}
              mb={6}
              minH={'150px'}
            >
              <Marquee pauseOnClick={true} direction={direction}>
                {dataToMap.map((_d, index) => {
                  return (
                    <Box
                      as={Link}
                      href={_d.link? _d.link: ''}
                      px={{ base: 0, md: 3 }}
                      mx={{ base: 0, md: 2 }}
                      target={'_blank'}
                    >
                      <Image
                        src={_d.logo}
                        alt={_d.name}
                        filter={'grayScale(1)'}
                        w={{ base: '60px', md: '140px' }}
                      />
                    </Box>
                  );
                })}
              </Marquee>
            </Box>
          );
      })}
    </Box>
  );

  /*return (
    <Box
      w={'100%'}
      h={{ base: '100px', md: '258px' }}
      borderRadius={{ base: '50px', md: '169px' }}
      className={'theme-box-shadow'}
      mt={{ base: 0, md: '-32px' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      overflow={'hidden'}
      position={'relative'}
      _before={{
        content: '""',
        position: 'absolute',
        bg: 'linear-gradient(to right, black, transparent)',
        left: -24,
        width: { base: 0, md: '40%' },
        height: { base: 0, md: '100%' },
        borderRadius: '60px',
        zIndex: 8,
      }}
      _after={{
        content: '""',
        position: 'absolute',
        bg: 'linear-gradient(to left, black, transparent)',
        right: -24,
        width: { base: 0, md: '40%' },
        height: { base: 0, md: '100%' },
        borderRadius: '60px',
        zIndex: 8,
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        maxW={'100vw'}
      >
        <Marquee pauseOnHover={true} pauseOnClick={true}>
          {[...data, ...data, ...data].map((_d, index) => {
            return (
              <Box
                // href={_d.link}
                px={{ base: 0, md: 6 }}
                mx={{ base: 0, md: 2 }}
              >
                <Image
                  src={_d.logo}
                  alt={_d.name}
                  filter={'grayScale(1)'}
                  w={{ base: '80px', md: '150px' }}
                />
              </Box>
            );
          })}
        </Marquee>
      </Box>
    </Box>
  );*/
};

export default DesktopPartners;
